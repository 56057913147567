
import { defineComponent } from "vue";
import NexusHQBeta from "@/components/modules/NexusHQBeta.vue";

export default defineComponent({
  components: {
    "nexus-hq-beta": NexusHQBeta,
  },
  setup() {},
});
