<template>
  <div>
    <nexus-hq-beta></nexus-hq-beta>
    <text-module title="Item Search"></text-module>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NexusHQBeta from "@/components/modules/NexusHQBeta.vue";

export default defineComponent({
  components: {
    "nexus-hq-beta": NexusHQBeta,
  },
  setup() {},
});
</script>